const espanolEstudios = [
    {
        id: 1,
        name: "Leccion 2-5 – Caminando En Sus Promesas",
        path: 'Leccion-2-5-Caminando-En-Sus-Promesas.pdf'
    },
    {
        id: 2,
        name: "Leccion 3-19 – Cuidando mi Actitud y Prioridades I",
        path: 'Leccion-3-19-Cuidando-mi-Actitud-y-Prioridades-I.pdf'
    },
    {
        id: 3,
        name: "Leccion 4-2 – Cuidando mi Actitud y Prioridades II",
        path: 'Leccion-4-2-Cuidando-mi-Actitud-y-Prioridades-II.pdf'
    },
    {
        id: 4,
        name: "Leccion 4-9 – Ahorra te toca a Ti",
        path: 'Leccion-4-9-Ahorra-te-toca-a-Ti.pdf'
    },
    {
        id: 5,
        name: "Leccion 4-23 – Consagrando mi Vida I",
        path: 'Leccion-4-23-Consagrando-mi-Vida-I.pdf'
    },
    {
        id: 6,
        name: "Leccion 4-30 – El Servicio Como Adoracion",
        path: 'Leccion-4-30-El-Servicio-Como-Adoracion.pdf'
    },
    {
        id: 7,
        name: "Leccion 5-7 – El Jugador de Equipo",
        path: 'Leccion-5-7-El-Jugador-de-Equipo.pdf'
    },
    {
        id: 8,
        name: "Leccion 5-14 – Nos Consagramos para que El nos Use",
        path: 'Leccion-5-14-Nos-Consagramos-para-que-El-nos-Use.pdf'
    },
    {
        id: 9,
        name: "Leccion 7-30 – Restaurados",
        path: 'Leccion-7-30-Restaurados.pdf'
    },
    {
        id: 10,
        name: "Leccion 8-13 – Cuida tu Huerto",
        path: 'Leccion-8-13-Cuida-tu-Huerto.pdf'
    },
    {
        id: 11,
        name: "Leccion 8-20 – Los Desafios de la Vida",
        path: 'Leccion-8-20-Los-Desafios-de-la-Vida.pdf'
    },
    {
        id: 12,
        name: "Leccion 9-10 – De lo Ordinario a los Extraordinario",
        path: 'Leccion-9-10-De-lo-Ordinario-a-los-Extraordinario.pdf'
    },
    {
        id: 13,
        name: "Leccion 9-17 – GDV – Enfocados con Proposito",
        path: 'Leccion-9-17-GDV-Enfocados-con-Proposito.pdf'
    },
    {
        id: 14,
        name: "Leccion 10-01 – GDV – La Presencia de Dios",
        path: 'Leccion-10-01-GDV-La-Presencia-de-Dios.pdf'
    },
    {
        id: 15,
        name: "Leccion 10-08 – Como Sentirme Seguro con Dios",
        path: 'Leccion-10-08-Como-Sentirme-Seguro-con-Dios.pdf'
    },
    {
        id: 16,
        name: "Leccion 10-15 – Bases del Ministerio",
        path: 'Leccion-10-15-Bases-del-Ministerio.pdf'
    },
    {
        id: 17,
        name: "Leccion 10.29 – Compartiendo de Cristo a mi Familia",
        path: 'Leccion-10.29-Compartiendo-de-Cristo-a-mi-Familia.pdf'
    },
    {
        id: 18,
        name: "Leccion 11-26 – Conquistando el Caracter de Cristo",
        path: 'Leccion-11-26-Conquistando-el-Caracter-de-Cristo.pdf'
    },
    {
        id: 19,
        name: "Leccion 12-10 – La Integridad del Discipulo",
        path: 'Leccion-12-10-La-Integridad-del-Discipulo.pdf'
    },
    {
        id: 20,
        name: "Leccion Marzo 5 – El Discipulo y su Compromiso (estudiante)",
        path: 'Leccion-Marzo-5-El-Discipulo-y-su-Compromiso-estudiante.pdf'
    },
    {
        id: 21,
        name: "Leccion Marzo 5 – El Discipulo y su Compromiso (Maestro)",
        path: 'Leccion-Marzo-5-El-Discipulo-y-su-Compromiso-Maestro.pdf'
    },
    {
        id: 22,
        name: "Lesson 2-12 – El Espiritu Santo Nos Pertenece",
        path: 'Lesson-2-12-El-Espiritu-Santo-Nos-Pertenece.pdf'
    },
]

export default espanolEstudios;