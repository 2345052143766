const englishVersion = [
    {
        id: 1,
        name: "Lesson 1-15 – What is the Heart Soul of the Church",
        path: 'Lesson-1-15-What-is-the-Heart-Soul-of-the-Church.pdf'
    },
    {
        id: 2,
        name: "Lesson 2-12 – The Holy Spirit Belongs to us",
        path: 'Lesson-2-12-The-Holy-Spirit-Belongs-to-us.pdf'
    },
    {
        id: 3,
        name: "Lesson 2-5 – Everyone Counts in the Church",
        path: 'Lesson-2-5-Everyone-Counts-in-the-Church.pdf'
    },
    {
        id: 4,
        name: "Lesson 4-2 – Building a Relationship with the Spirit",
        path: 'Lesson-4-2-Building-a-Relationship-with-the-Spirit.pdf'
    },
    {
        id: 5,
        name: "Lesson 4-9 – The Church Comes Alives",
        path: 'Lesson-4-9-The-Church-Comes-Alive.pdf'
    },
    {
        id: 6,
        name: "Lesson 4-10 – Let Us be of One Spirit",
        path: 'Lesson-4-10-Let-Us-be-of-One-Spirit.pdf'
    },
    {
        id: 7,
        name: "Lesson 4-23 Walk by the Spirit and Live",
        path: 'Lesson-4-23-Walk-by-the-Spirit-and-Live.pdf'
    },
    {
        id: 8,
        name: "Lesson 4-30 – Let us be One Spirit",
        path: 'Lesson-4-30-Let-us-be-One-Spirit.pdf'
    },
    {
        id: 9,
        name: "Lesson 5-7 – The Healing Power of the Tongue",
        path: 'Lesson-5-7-The-Healing-Power-of-the-Tongue.pdf'
    },
    {
        id: 10,
        name: "Lesson 5-14 – Discovering true Acceptance",
        path: 'Lesson-5-14-Discovering-true-Acceptance.pdf'
    },
    {
        id: 11,
        name: "Lesson 6-11 – Learning to use His Authority",
        path: 'Lesson-6-11-Learning-to-use-His-Authority.pdf'
    },
    {
        id: 12,
        name: "Lesson 6-18 Dealing with my Anger",
        path: 'Lesson-6-18-Dealing-with-my-Anger.pdf'
    },
    {
        id: 13,
        name: "Lesson 7-30 – Caring is the Remedy",
        path: 'Lesson-7-30-Caring-is-the-Remedy.pdf'
    },
    {
        id: 14,
        name: "Lesson 8-13 – It Pays to Plan Gods Way",
        path: 'Lesson-8-13-It-Pays-to-Plan-Gods-Way.pdf'
    },
    {
        id: 15,
        name: "Lesson 8-20 – The Struggle for the Faith",
        path: 'Lesson-8-20-The-Struggle-for-the-Faith.pdf'
    },
    {
        id: 16,
        name: "Lesson 8-27 – God is Always Willing and Able",
        path: 'Lesson-8-27-God-is-Always-Willing-and-Able.pdf'
    },
    {
        id: 17,
        name: "Lesson 9-10 – A Faith that Really Works",
        path: 'Lesson-9-10-A-Faith-that-Really-Works.pdf'
    },
    {
        id: 18,
        name: "Lesson 9-17 – How Big is our Reward",
        path: 'Lesson-9-17-How-Big-is-our-Reward.pdf'
    },
    {
        id: 19,
        name: "Lesson 10-01 – The How and Why be ready for Jesus",
        path: 'Lesson-10-01-The-How-and-Why-be-ready-forJesus.pdf'
    },
    {
        id: 20,
        name: "Lesson 10-08 – The God Who Shares his Patients",
        path: 'Lesson-10-08-The-God-Who-Shares-his-Patients.pdf'
    },
    {
        id: 21,
        name: "Lesson 10-15 – Faith and Treasure",
        path: 'Lesson-10-15-Faith-and-Treasure.pdf'
    },
    {
        id: 22,
        name: "Lesson 10-29 – Faith and the Future",
        path: 'Lesson-10-29-Faith-and-the-Future.pdf'
    },
    {
        id: 23,
        name: "Lesson 11-26 – Faith and Generosity",
        path: 'Lesson-11-26-Faith-and-Generosity.pdf'
    },
    {
        id: 24,
        name: "Lesson 12-9 – Faith and Obedience",
        path: 'Lesson-12-9-Faith-and-Obedience.pdf'
    }
]

export default englishVersion;