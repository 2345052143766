import React from 'react';

import Heading from '../../components/Heading/Heading';
import jovenes from '../Home/images/Jovenes-1.jpg';
import './Youth.css'

function Youth() {
  return (
    <div className="youth-wrapper">
        <div className="imageBanner" style={{ backgroundImage: `url(${jovenes})`}}></div>
        <Heading subtitle="Welcome to" title="MFV Youth" paragraph="Nuestra visión es responder a las necesidades sociales y espirituales de los jóvenes dentro de nuestra iglesia y comunidad proporcionando programas, actividades y eventos relativos que les permitan construir una base sólida de fe que les permita expresar y compartirla a través de sus propias palabras, acciones y hechos; alentarlos a que utilicen sus talentos individuales en programas y ministerios que les interesen para nutrir su crecimiento como personas."/>
    </div>
  );
}

export default Youth;
