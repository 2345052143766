import React from 'react';
import Youtube from 'react-youtube';

function Video({title, key, videoId, description}) {
    
    const opts = {
        height: '200',
        width: '350'
    }

    return (
        <div key={key} className="video-wrapper">
            <h6 className="text-center"> {title} </h6>
            <Youtube ClassName="mx-auto my-auto" videoId={videoId} opts={opts}/>
            <p> {description}</p>
        </div>
  );
}

export default Video;