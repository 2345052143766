import React from 'react';

import {Container, Row, Col} from "react-bootstrap";

import './Banner3.css';

import pastor from './images/pastor_bg-1.jpg';

function Banner3() {
  return (
    <div className="Banner3-wrapper" style={{ backgroundImage: `url(${pastor})`}}>
        <Container>
            <Row className="py-5 d-flex justify-content-center">
                <Col className="my-3" sm={6}>
                    <div className="subtitle pb-3"> Ama lo que Dios Ama </div>
                    <h3 className="title pb-4"> "lo que determina que tan cerca estamos de Dios, es cuanto amamos lo que Dios ama... </h3>
                    <h3 className="mt-3 my-5"> 
                        Benjamin Ortega
                    </h3>
                </Col>
                <Col sm={6}></Col>
            </Row>
        </Container>
    </div>
  );
}

export default Banner3;
