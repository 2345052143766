import React from 'react';

// Components
import Banner4 from '../../components/Banner4/Banner4';
import Beliefs from '../../components/Beliefs/Beliefs';

import './AboutUs.css';

function AboutUs() {
  return (
    <div className="Home">
        <Banner4/>
        <Beliefs/>
        <div className="history-container white-font p-5">
          <h1 className="text-center white-font"> Nuestra Historia </h1>
          <hr className="px-5"/>
          <div className="p-5">
            <p className="white-font">
              Benjamín y Rosa Ortega son los pastores principales y fundadores de los Ministerios Fuente de Vida en Los Angeles California.
              Después de haber sido llamados por Dios en el 2005 para servir a tiempo completo en el ministerio y habiendo servido previamente 
              en dos diferentes congregaciones, entonces nace MFV en el 2015. Nuestro ministerio ha sido la base para un mover especial del 
              Espíritu Santo en la ciudad de Glendale CA, el cual avanza a pasos agigantados alcanzando a las generaciones emergentes de 
              latinos del Sur de California. Los pastores Ortega son los padres de dos hermosos hijos Sarah y David, quienes a su vez dirigen 
              la adoración en la congregación.
            </p>
          </div>
        </div>
    </div>
  );
}

export default AboutUs;
