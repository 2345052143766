import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import MainNav from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

// Pages
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Sermons from './pages/Sermons/Sermons';
import Discipleship from './pages/Discipleship/Discipleship';
import LifeGroup from './pages/LifeGroup/LifeGroup';
import Youth from './pages/Youth/Youth';
import NotFound from './pages/NotFound/NotFound';

import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

require('dotenv').config();

function App() {

  const navLinks = [
    {
      id: 1,
      name: "Acerca de Nosotros",
      path: "/acerca-de-nosotros"
    },
    {
      id: 2,
      name: "Ministries",
      path: [
        {
          id: 1,
          name: "Discipulados",
          path: "/discipulados"
        },
        {
          id: 2,
          name: "Grupos de Vida",
          path: "/Grupos-de-Vida"
        },
        {
          id: 3,
          name: "MFV Youth",
          path: "/mfv-youth"
        }
      ]
    },
    {
      id: 3,
      name: "Sermons",
      path: "/sermons"
    },
  ]

  return (
      <div className="App">
        <MainNav links={navLinks}/>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/acerca-de-nosotros" component={AboutUs}/>
          <Route path="/discipulados" component={Discipleship}/>
          <Route path="/sermons" component={Sermons}/>
          <Route path="/grupos-de-Vida" component={LifeGroup}/>
          <Route path="/mfv-youth" component={Youth}/>
          <Route component={NotFound}/>
        </Switch>
        <Footer/>
      </div>
  );
}

export default App;
