import React from 'react';
import { Container, Accordion, Card } from 'react-bootstrap';

import englishVersion from './studies/englishStudies';
import espanolVersion from './studies/espanolEstudios';

import './Discipleship.css';

import Heading from '../../components/Heading/Heading';
import lens from './images/Nuestra-Creencia.jpeg';

function Discipleship() {

  const claseList = [
    {
      id: 1,
      title: "Clase 101",
      content: "Es un gran paso para aprender acerca de la historia de la iglesia de MFV, lo que creemos, y cómo convertirse en un miembro de la familia MFV. También tendrás la oportunidad de bautizarse después de clase."
    },
    {
      id: 2,
      title: "Clase 201",
      content: "Se puede aprender lo que significa ser más como Jesús, aprender a pasar tiempo con Dios a través de la oración y el estudio bíblico, descubrir la importancia del diezmo, y entender el valor de la comunidad."
    },
    {
      id: 3,
      title: "Clase 301",
      content: "Aprenderás cómo Dios puede usar tus dones espirituales, corazón (pasiones), habilidades, personalidad y experiencias para ayudar a los demás."
    },
    {
      id: 4,
      title: "Clase 401",
      content: "Le ayudará a descubrir su llamamiento, aprender a compartir su historia con los demás, anotar su testimonio personal, y ver cómo impactar el mundo a su alrededor con el amor de Cristo."
    },
  ]

  return (
    <div className="discipleship-wrapper">
        <div className="imageBanner" style={{ backgroundImage: `url(${lens})`}}></div>
        <Heading 
          subtitle="Ministries Duente De Vida" 
          title="Clases de Discipulados" 
          paragraph="¿Alguna vez se ha preguntado qué cree Fuente De Vida? ¿Quieres comenzar tu viaje espiritual o crecer en tu fe? Nuestros seminarios de vida y servicio cristiano le ayudarán a dar el siguiente paso en su viaje espiritual."
          paragraph2="Nuestras clases te enseñan todo sobre lo que tiene que ver con seguir a Cristo y te da las herramientas que necesitas para cada paso del viaje. Empiece con la clase 101 y vea cómo Dios transforma su vida a medida que crece en su fe."
        />
        {claseList.map(clase => 
          <Heading key={clase.id} title={clase.title} paragraph={clase.content} />
        )}
        <Container>
          <hr className="divder" px-5 mx-5/>
          <Accordion className="py-5" defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle className="no-border btn" eventKey="0">
                  <h5> English Studies </h5>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {englishVersion.map(file => {
                    return(
                      <div key={file.id}>
                        <h5>
                          <a href={require('../../assets/pdf/english/' + file.path)}> {file.name} </a>
                        </h5>
                      </div>
                    )
                  })}
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle className="no-border btn" eventKey="1">
                  <h5> Estudios en Español </h5>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {espanolVersion.map(file => {
                    return(
                      <div key={file.id}>
                        <h5>
                          <a href={require('../../assets/pdf/espanol/' + file.path)}> {file.name} </a>
                        </h5>
                      </div>
                    )
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
    </div>
  );
}

export default Discipleship;
