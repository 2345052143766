import React from 'react';

import {Container, Row, Col} from "react-bootstrap";

import './Banner2.css';

import groupPhoto from './images/Grupos-de-Vida.jpg';

function Banner1() {
  return (
    <div className="Banner2-wrapper p-5">
        <Container>
            <Row className="py-5 d-flex justify-content-center">
                <Col className="my-3" sm={6}>
                    <div className="title pb-4"> Unete a un grupo de vida! </div>
                    <p className="mt-3 my-5"> 
                        Te invitamos a que te unas a uno de nuestros grupos de vida para compañerismo y crecimiento.
                    </p>
                    <form action="mailto:info@ministeriosfdv.com" method="GET">
                        <input style={{ fontSize: `1rem` }}  className="btn-gold rounded-0 px-5 py-3" type="submit" value="Contactanos" />
                    </form>
                </Col>
                <Col className="background" style={{ backgroundImage: `url(${groupPhoto})`}} sm={6}>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Banner1;
