import React from 'react';
import { Carousel, Button, Container, Col, Row } from 'react-bootstrap';

import './Home.css'

import girlsDancing from './images/d1png.png';
import pool from './images/pool.jpg';
import beliefs from './images/ourbeliefs.jpg';
import singing from './images/singing.jpg';
import people from './images/peace.jpg';
import jovenes from './images/Jovenes-1.jpg';

// Components
import Heading from '../../components/Heading/Heading';
import Ministries from '../../components/Ministries/Ministries';
import Banner1 from '../../components/Banner1/Banner1';
import Banner2 from '../../components/Banner2/Banner2';
import Banner3 from '../../components/Banner3/Banner3';

function Home() {

  const carousel = [
    {
      id: 1,
      image: girlsDancing
    },
    {
      id: 2,
      image: pool
    },
    {
      id: 3,
      image: beliefs
    },
    {
      id: 4,
      image: singing
    },
    {
      id: 5,
      image: people
    },
  ]

  return (
    <div className="home-wrapper">
        <Carousel className="carousel">
          {carousel.map(picture => 
            <Carousel.Item key={picture.id} className="item">
              <div className="d-block w-100" style={{ backgroundImage: `url(${picture.image})`}}></div>
            </Carousel.Item>
          )}
        </Carousel>
        <Heading
          subtitle="Ministerios Fuente De Vida"
          title="Bienvenidos a nuestra pagina" 
          paragraph="Somos un ministerio bíblico, dedicado a la restauración espiritual de las familias 
            en nuestra comunidad. Estamos comprometidos en brindarle a usted y toda su familia, 
            un sólido alimento bíblico y diversas oportunidades para desarrollar los dones y 
            ministerios que Dios le ha dado."
          />
          <div className="contact-banner d-flex">
            <Container>
              <Row className="py-5">
                  <Col className="mb-3" sm={9}>
                    <h2 className="contact-banner-title"> Preguntas? </h2>
                    <div className="contact-banner-desc"> Tienes preguntas o necesitas mas informacion? dejanos saber, responderemos lo mas pronto posible. </div>
                  </Col>
                  <Col sm={3}>
                    <Button className="rounded-0 p-4 pl-5 pr-5" href="mailto:info@ministeriosfdv.com" variant="outline-light"> Contactenos </Button>
                  </Col>
              </Row>
            </Container>
          </div>
          <Ministries/>
          <Banner1/>
          <Banner2/>
          <Banner3/>
          <div className="imageBanner" style={{ backgroundImage: `url(${jovenes})`}}></div>
    </div>
  );
}

export default Home;
