import React from 'react';

import {Container, Row, Col, Button} from "react-bootstrap";

import './Banner1.css';

import pastors from './images/Pastores.jpg'

function Banner1() {
  return (
    <div className="Banner1-wrapper p-5">
        <Container>
            <Row className="py-5 d-flex justify-content-center">
                <Col className="my-3" sm={6}>
                    <div className="subtitle pb-3"> Ministeries Fuente De Vida </div>
                    <h1 className="title pb-4"> Nuestros Pastores </h1>
                    <p className="mt-3 my-5"> 
                        Benjamín y Rosa Ortega son los pastores principales y fundadores de los Ministerios Fuente de Vida en Los Angeles California. 
                        Después de haber sido llamados por Dios en el 2005 para servir a tiempo completo en el ministerio y habiendo servido previamente 
                        en dos diferentes congregaciones, entonces nace MFV en el 2015. Nuestro ministerio ha sido la base para un mover especial del 
                        Espíritu Santo en la ciudad de Glendale CA, el cual avanza a pasos agigantados alcanzando a las generaciones emergentes de latinos
                        del Sur de California. Los pastores Ortega son los padres de dos hermosos hijos Sarah y David, quienes a su vez dirigen la adoración
                        en la congregación.
                    </p>
                    <Button className="btn-gold rounded-0 px-4 py-2" href="/acerca-de-nosotros"> Más información </Button>
                </Col>
                <Col sm={6}>
                    <Col className="mx-auto" sm={9}>
                        <div className="m-auto">
                            <img className="pastors" width="1817" height="1535" src={pastors} alt="Benjamín y Rosa Ortega"/>
                        </div>
                    </Col>
                    <Col className="mx-auto my-3" sm={9}>
                        <div className=""> Benjamín y Rosa Ortega </div>
                        <div className="text-muted"> Lead Pastors | Ministerios fuente de Vida </div>
                    </Col>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Banner1;
