import React from 'react';
import {Nav, Navbar, NavDropdown } from 'react-bootstrap';

import logo from './images/MFDV-Horiz.png';
import './Navbar.css';

function navlink(link) {
    if (Array.isArray(link.path)) {
        return (
            <NavDropdown className="mx-3" key={link.id} title={link.name} id="basic-nav-dropdown">
                {link.path.map(sublink => 
                    <NavDropdown.Item key={sublink.id} href={sublink.path}>{sublink.name}</NavDropdown.Item>
                )}
            </NavDropdown>
        )
    } else {
        return (
            <Nav.Link className="mx-3" key={link.id} href={link.path}>{link.name} </Nav.Link>
        )
    }
}

function MainNav({links}) {
  return (
    <Navbar className="navbar bg-faded pt-3 pb-3" bg="light" expand="lg">
        <div className="container-xl">
            <Navbar.Brand href="/">
                <img src={logo}
                width="111"
                height="52"
                className="d-inline-block aligin-top"
                alt="ministeriosdfv Logo"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {links.map(link => navlink(link))}
                </Nav>
            </Navbar.Collapse>
        </div>
    </Navbar>
  );
}

export default MainNav;
