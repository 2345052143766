import React from 'react';
import { Carousel } from 'react-bootstrap';

import Heading from '../../components/Heading/Heading';
import people1 from './images/Grupos-de-Vida2.jpg';
import people2 from '../../components/Banner2/images/Grupos-de-Vida.jpg'

function AboutUs() {

  const carousel = [
    {
      id: 1,
      image: people1
    },
    {
      id: 2,
      image: people2
    },
  ]

  return (
    <div className="Home">
        <Carousel className="carousel">
          {carousel.map(picture => 
            <Carousel.Item key={picture.id} className="item">
              <div className="d-block w-100" style={{ backgroundImage: `url(${picture.image})`}}></div>
            </Carousel.Item>
          )}
        </Carousel>
        <Heading title="Grupos de Vida" paragraph="Estos grupos son los espacios que tomamos durante la semana para reunirnos en los hogares para compartir la Palabra de Dios, orar los unos por los otros y conocernos mejor."/>
    </div>
  );
}

export default AboutUs;
