import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Ministries() {
  return (
    <div className="ministries-wrapper">
        <div className="welcomeMessage m-5">
            <h6 className="text-center"> Involucrate </h6>
            <h1 className="underlineTitle text-center mt-3 mb-2 pb-4"> Nuestros Ministerios </h1>
        </div>
        <div className="typesOfMinistires">
            <Container className="mb-5">
                <Row className="py-5">
                    <Col sm={4}>
                        <h3 className="underlineTitle text-center pb-3">
                            <a className="decr-none mb-5" href="/discipleship"> Discipleship </a>
                        </h3>
                        <div className="mt-5 p-2 text-center">
                            ¿Alguna vez se ha preguntado qué cree Fuente De Vida? 
                            ¿Quieres comenzar tu viaje espiritual o crecer en tu fe? Nuestros seminarios de vida y servicio cristiano le ayudarán a dar el siguiente paso en su viaje espiritual.
                        </div>
                    </Col>
                    <Col sm={4}>
                        <h3 className="underlineTitle text-center pb-3">
                            <a className="decr-none mb-5" href="/mfv-youth"> MFV Youth </a>
                        </h3>
                        <div className="mt-5 p-2 text-center"> Nuestra visión es responder a las necesidades sociales y espirituales de los jóvenes dentro de nuestra iglesia y comunidad proporcionando programas, actividades y eventos relativos que les permitan construir una base sólida de fe… </div>
                    </Col>
                    <Col sm={4}>
                        <h3 className="underlineTitle text-center pb-3">
                            <a className="decr-none mb-5" href="/grupos-de-vida"> Grupos de Vida </a>
                        </h3>
                        <div className="mt-5 p-2 text-center"> Estos grupos son los espacios que tomamos durante la semana para reunirnos en los hogares para compartir la Palabra de Dios, orar los unos por los otros y conocernos mejor.</div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
  );
}

export default Ministries;
