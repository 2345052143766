import React from 'react';

import {Container, Row, Col} from "react-bootstrap";

import './Banner4.css';

import pastors from './images/pastors.jpg';

function Banner4() {
  return (
    <div className="Banner4-wrapper" style={{ backgroundImage: `url(${pastors})`}}>
        <Container>
            <Row className="py-5 d-flex justify-content-center">
                <Col sm={6}></Col>
                <Col className="my-3" sm={6}>
                    <div className="welcomeTitle pb-4"> Bienvenido! </div>
                    <p className="mt-3"> 
                        Somos un ministerio bíblico, dedicado a la restauración espiritual de las familias en nuestra comunidad.
                        Estamos comprometidos en brindarle a usted y toda su familia, un sólido alimento bíblico y diversas 
                        oportunidades para desarrollar los dones y ministerios que Dios le ha dado.
                    </p>
                    <h3 className="title pb-4 my-3 pt-3"> Horarios </h3>
                    <div> Miércoles 7:30pm – Estudio Bíblico </div>
                    <div> Viernes 7:30pm – Youth Group </div>
                    <div> Domingos 10am – Servicio De Adoración </div>

                    <form className="my-5" action="mailto:info@ministeriosfdv.com" method="GET">
                        <input style={{ fontSize: `1rem` }}  className="btn-gold rounded-0 px-5 py-3" type="submit" value="Contactanos" />
                    </form>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Banner4;
