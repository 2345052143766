const beliefsListing = [
    {
        id: 1,
        name: "Discipleship",
        description: "¿Alguna vez se ha preguntado qué cree Fuente De Vida? ¿Quieres comenzar tu viaje espiritual o crecer en tu fe? Nuestros seminarios de vida y servicio cristiano le ayudarán a dar el siguiente paso en su viaje espiritual."
    },
    {
        id: 2,
        name: "MFV Youth",
        description: "Nuestra visión es responder a las necesidades sociales y espirituales de los jóvenes dentro de nuestra iglesia y comunidad proporcionando programas, actividades y eventos relativos que les permitan construir una base sólida de fe…"
    },
    {
        id: 3,
        name: "La Segunda Venida de Cristo",
        description: "Al final de la Gran Tribulación se producirá lo que propiamente se llama la Segunda Venida de Cristo. Las señales que precederán a la Segunda Venida de Cristo son: La congregación de los ejércitos de la bestia en el valle de Megido o Armagedn (Apocalipsis 19:9) y fenómenos en el cielo y el mar (Joel 2:30-31; San Lucas 21:25-25; Apocalipsis…"
    },
    {
        id: 4,
        name: "La Gran Tribulación",
        description: "Es un periodo de aflicción sin precedentes que vendrá sobre todos los moradores de la tierra (Apocalipsis 3:10 ); pero especialmente sobre Israel (Jeremías 30:7)"
    },
    {
        id: 5,
        name: "El Tribunal de Cristo",
        description: "Cuando la iglesia sea raptada se realizara el Tribunal de Cristo ( San Mateo 16:27; Apocalipsis 22:12 ), en el cual, serán juzgadas las obras del creyente. El Juez en este tribunal será el Señor Jesús (2a. Corintios 5:10) y la finalidad del juicio es la de determinar si un creyente merece recibir galardón o no."
    },
    {
        id: 6,
        name: "El Rapto de la Iglesia",
        description: "En el retorno de Cristo a la tierra tendrá dos apariciones: La primera para arrebatar a su iglesia, y la segunda para establecer su reinado milenial. Ambas apariciones están separadas por Un periodo de siete años y poseen características muy diferentes. 1a. Tesalonicenses 4:15-17."
    },
    {
        id: 7,
        name: "El Rapto de la Iglesia",
        description: "Consiste en devolver a Dios el 10% de los ingresos que Él nos concede (Génesis 28:22). El diezmo es una práctica que se originó como una expresión de gratitud por las bendiciones recibidas de Dios (Génesis 14:18-20 ) y como Un reconocimiento de la mediación sacerdotal (Números 18:21). El diezmo se practicó mucho antes que la ley de Moisés fuera…"
    },
    {
        id: 8,
        name: "El Matrimonio",
        description: "Es una institución divina que tiene como finalidad brindar una ayuda mutua a los cónyuges; hombre y mujer (Génesis 2:15 ), permitir la satisfacción del instinto sexual de manera responsable y santa entre un hombre y una mujer ( la. Corintios 7:2-5~9 ) y posibilitar la multiplicación adecuada de la raza (Génesis 1:25). El matrimonio se da entre un hombre…"
    },
    {
        id: 9,
        name: "El Ayuno",
        description: "Es el ejercicio espiritual que consiste en periodos especiales de oración que van acompañados de la abstinencia total o parcial de alimentos. San Mateo 6:16-15; 9:14-15; Hechos 13:3; 14:23."
    },
    {
        id: 10,
        name: "La Oración",
        description: "Es el ejercicio espiritual a través del cual un creyente establece contacto directo con Dios. A través de la Biblia Dios habla al hombre, a través de la oración el hombre habla a Dios. San Lucas 11:2; San Juan 15:16; 16:23; la. Tesalonicenses 5:17."
    },
    {
        id: 11,
        name: "La Santa Cena",
        description: "Es la segunda de las ordenanzas. Mientras que el bautismo en agua se recibe una sola vez en la vida; la Santa Cena es una ceremonia en la que el cristiano debe participar periódicamente. La Santa Cena tiene varios significados, el primero de ellos: a)Un memorial: San Mateo 26:26-29; 1a. Corintios 11:23-25 b)Una proclamación: la. Corintios 11:26 c) Una comunión:…"
    },
    {
        id: 12,
        name: "El Bautismo en Agua",
        description: "El bautismo es la ceremonia que expresa, simbólicamente: a)La muerte del creyente a la vida de pecado (Romanos 6:3,6) b)Su sepultura al mundo (Romanos 6:4; Colosenses 2:12) c)Su resurrección a una nueva vida (Romanos 6:4-5, 8-11). No es Un requisito para la salvación; pues, esta depende únicamente de los méritos de Cristo. No obstante, el bautismo es necesario para tener…"
    },
    {
        id: 13,
        name: "Los Dones del Espíritu Santo",
        description: "Los Dones del Espíritu Santo: Son capacidades sobrenaturales que Dios otorga a los creyentes para edificación de la iglesia. Los dones del Espíritu Santo son manifestaciones completamente milagrosas que no podrían ser ejercidas sin la intervención de Dios. Los Dones del Espíritu Santo son nueve (1a. Corintios 12:7-11)."
    },
    {
        id: 14,
        name: "El Bautismo en el Espíritu Santo",
        description: "Es la investidura de poder que Cristo otorga a los creyentes para un testimonio eficaz (Hechos 1:5). El Bautismo en el Espíritu Santo fue ofrecido inicialmente por Juan el Bautista ( San Mateo 3:11) y, posteriormente, prometido por el Señor Jesús ( San Lucas 24:49 ) y confirmándose sobre la vida de los creyentes del principio según (Hechos 2:1-4 )…"
    },
    {
        id: 15,
        name: "La Santificación",
        description: "El significado básico de santificación es la acción por medio de la cual algo es separado o consagrado a Dios. Los creyentes, al ser santificados, son separados para Dios; implicándose con ello las transformaciones espirituales que corresponden a su nueva relación con El. Hebreos 10:12-14; Filipenses 1:6; Filipenses 3:20."
    },
    {
        id: 16,
        name: "La Regeneración",
        description: "Llamada también nuevo nacimiento, es el acto creador de Dios por medio del cual otorga al hombre una naturaleza espiritual. Efesios 2:1, San Juan 3:3, 2a. Corintios 5:17."
    },
    {
        id: 17,
        name: "La Justificación",
        description: "La justificación es el acto por el que Dios declara a una persona inocente, librándola de toda acusación que podría presentarse en su contra, siendo Jesucristo el Único Mediador para poder dar la justificación a los hombres y ser aceptos delante de Dios como hijos amados. Romanos 5:1; 8:3; 2a. Corintios 5:21, San Juan 5:24."
    },
    {
        id: 18,
        name: "El Arrepentimiento",
        description: "En las Escrituras el arrepentimiento es presentado como un paso necesario para entrar en el reino de Dios. Es el dolor o pesar de haber ofendido a Dios con nuestras conductas pecaminosas. La idea que transmite el arrepentimiento es la necesidad de una conversión a Dios que incluye un cambio en la manera de pensar, sentir y actuar. San Mateo…"
    },
    {
        id: 20,
        name: "Grupos de Vida",
        description: "Estos grupos son los espacios que tomamos durante la semana para reunirnos en los hogares para compartir la Palabra de Dios, orar los unos por los otros y conocernos mejor."
    },
    {
        id: 21,
        name: "La Inspiración de las Escrituras",
        description: "Estos grupos son los espacios que tomamos durante la semana para reunirnos en los hogares para compartir la Palabra de Dios, orar los unos por los otros y conocernos mejor."
    },
    {
        id: 22,
        name: "La Trinidad de Dios",
        description: "Existe un Único Dios Verdadero que subsiste en Tres personas distintas: Padre, Hijo y Espíritu Santo. Estas Tres personas participan de la misma…"
    },
    {
        id: 23,
        name: "La Elección Incondicional",
        description: "En razón de que todos los hombres han pecado en Adán y que sin excepción son culpables y dignos de condenación, Dios no habría cometido ninguna injusticia si hubiera pasado por alto a todos para reservarlos al fuego eterno dejando que cosecharan lo que ellos mismos sembraron…"
    },
]

export default beliefsListing;