import React from 'react';

function NotFound() {
  return (
    <div className="Home">
        not found
    </div>
  );
}

export default NotFound;