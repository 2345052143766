import React from 'react';


import './Heading.css'

function Heading({subtitle, title, paragraph, paragraph2}) {
  return (
    <div className="Heading-wrapper">
        <div className="welcomeMessage m-5">
        <h6 className="text-center"> {subtitle} </h6>
        <h1 className="underlineTitle text-center mt-3 mb-2 pb-4"> {title} </h1>
        <div className="content mr-auto ml-auto mt-4"> {paragraph} </div>
        <div className="content mr-auto ml-auto mt-4"> {paragraph2} </div>
        </div>
    </div>
  );
}

export default Heading;
