import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import Card from '../../components/Card/Card';
import Listing from './List';

function Beliefs() {

  return (
    <div className="beliefs-wrapper">
        <Container>
            <div className="Heading-wrapper">
                <div className="welcomeMessage m-5">
                    <h6 className="text-center"> NUESTRA CREENCIA </h6>
                    <h1 className="underlineTitle text-center mt-3 mb-2 pb-4"> En que Creemos? </h1>
                </div>
            </div>
            <Row className="py-5">
                {Listing.map(list =>
                    <Col key={list.id} sm={4}>
                        <Card title={list.name} description={list.description}/>
                    </Col>
                )}
            </Row>
        </Container>
    </div>
  );
}

export default Beliefs;
