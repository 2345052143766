import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import './Sermons.css'

import Heading from '../../components/Heading/Heading';
import Video from '../../components/Video/Video';

const channelId = "UUx-AiW_AyW8zX1MybxsFkew";

function Sermons() {

  const [playlist, setPlaylist] = useState([]);
  const isLoaded = useRef(false);

  const fetchPlaylist = async () => {
    axios.get("https://www.googleapis.com/youtube/v3/playlistItems", {
      params: {
        part: "snippet",
        playlistId: channelId,
        key: process.env.REACT_APP_API_KEY,
        maxResults: 1000,
      }
    }).then(isLoaded.current = true )
      .then(res => setPlaylist(res.data.items))
  }

  useEffect(() => {
    fetchPlaylist();
  }, [])

  return (
    <div className="Sermons my-5 p-5">
      <Heading subtitle="Ministries Fuente De Vida" title="Sermones" />
        <Container className="d-flex justify-content-center">
          <Row>
            { playlist.map( res => 
              <Col key={res.id} sm={4}>
                <Video
                  title={res.snippet.title} 
                  description={res.snippet.description} 
                  videoId={res.snippet.resourceId.videoId}
                />
              </Col>
            )}
          </Row>

        </Container>
    </div>
  );
}

export default Sermons;
