import React from 'react';

function Card({title, description}) {
    return(
        <div className="card-wrapper">
            <h3 className="underlineTitle text-center my-5 pb-3"> {title} </h3>
            <div className="mt-5 p-2 text-center"> {description} </div>
        </div>
    )
}

export default Card;